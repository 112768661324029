// dependencies.
import React from 'react'
import Img from 'gatsby-image'
// components.
import PageHead from 'src/components/head/PageHead'
import Heading from 'src/components/heading/Heading'
import { Header } from 'src/components/layout'
// utils.
import buildImageObject from 'src/js/utils/buildImgObject'
// constants
import { isUk } from 'src/constants'

// Styles & Images
import 'static/contact/scss/styles.scss'

// Data
let data

if (isUk) {
  data = require('src/data/pages/Contents/contact__uk.json')
} else {
  data = require('src/data/pages/Contents/contact.json')
}

const iconSVG = (icon, title) => {
  switch (icon) {
    case 'Alert':
      return (
        <svg
          className="x-contact__txt-txt1-icon"
          role="presentation"
          viewBox="0 0 27 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M26.4606466,22.73473 L14.2670983,0.416534778 C13.9631305,-0.138844926 13.0372918,-0.138844926 12.7333241,0.416534778 L0.539775671,22.73473 C0.394324058,23.0008316 0.4012918,23.3227286 0.558936961,23.581963 C0.716582122,23.8411975 1.00051761,24 1.30709825,24 L25.694195,24 C26.0007757,24 26.2847112,23.8411975 26.4414853,23.581963 C26.5991305,23.3218702 26.6060983,23.0008316 26.4606466,22.73473 Z"
              id="Shape"
              fill="#FF2626"
            />
            <path
              d="M14.9910968,7.71428571 L14.3692258,16.2857143 L12.6272903,16.2857143 L12.0054194,7.71428571 L14.9910968,7.71428571 Z M13.4982581,21.4285714 C12.6429677,21.4285714 11.9496774,20.7462857 11.9496774,19.9045714 C11.9496774,19.0628571 12.6429677,18.3805714 13.4982581,18.3805714 C14.3535484,18.3805714 15.0468387,19.0628571 15.0468387,19.9045714 C15.0468387,20.7462857 14.3535484,21.4285714 13.4982581,21.4285714 Z"
              fill="#FFFFFF"
            />
          </g>
        </svg>
      )
    case 'twitter':
      return (
        <svg viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>{title}</title>
          <path
            d="M.056 0l8.88 11.873L0 21.527h2.011l7.824-8.452 6.32 8.452H23L13.62 8.986 21.938 0h-2.011l-7.205 7.784L6.9 0H.056zm2.958 1.481h3.144l13.884 18.564h-3.144L3.014 1.481z"
            fill="#fff"
          />
        </svg>
      )
    case 'facebook':
      return (
        <svg version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <title>{title}</title>
          <g fill="none" fillRule="evenodd">
            <path
              d="m30.234 0h-28.468c-0.97561 0-1.7661 0.79049-1.7661 1.7661v28.468c0 0.97537 0.79049 1.7661 1.7661 1.7661h15.326v-12.392h-4.1702v-4.8295h4.1702v-3.5616c0-4.1332 2.5244-6.3838 6.2115-6.3838 1.7661 0 3.2841 0.13153 3.7264 0.19028v4.3194l-2.5572 0.001199c-2.0053 0-2.3935 0.95283-2.3935 2.3511v3.0834h4.7824l-0.62275 4.8295h-4.1596v12.392h8.1545c0.97537 0 1.7661-0.79073 1.7661-1.7661v-28.468c0-0.97561-0.79073-1.7661-1.7661-1.7661z"
              fill="#fff"
            />
          </g>
        </svg>
      )
    case 'email':
      return (
        <svg version="1.1" viewBox="0 0 32 23" xmlns="http://www.w3.org/2000/svg">
          <title>{title}</title>
          <g fill="none" fillRule="evenodd">
            <path
              d="m0.3861 22.181c3.1486-2.1449 7.4142-5.047 12.797-8.7066 0.013058-0.008903 0.026148-0.017738 0.039268-0.026504 1.77 0.7655 3.784 0.76555 5.5546-1.034e-4 0.012848 0.0085872 0.025666 0.01724 0.038453 0.025959 5.3665 3.6679 9.6268 6.5779 12.781 8.7301-0.36508 0.48332-0.94451 0.79569-1.5969 0.79569h-28c-0.66261 0-1.25-0.32223-1.6139-0.81854zm-0.3861-2.1569v-15.421c2.9161 1.986 6.6938 4.5561 11.333 7.7104-4.6396 3.1543-8.4174 5.7244-11.333 7.7104zm32 0.033535c-2.9215-1.9938-6.7004-4.575-11.337-7.7439 4.6366-3.1689 8.4155-5.7501 11.337-7.7439v15.488zm0-17.909c-3.1909 2.1769-7.5856 5.1786-13.184 9.0051-1.6981 1.1578-3.9332 1.1578-5.6323-6.485e-4 -5.6065-3.8115-10.001-6.8012-13.183-8.9696l6.013e-13 -0.18394c-1.3527e-16 -1.1046 0.89543-2 2-2h28c1.1046-2.0291e-16 2 0.89543 2 2v0.14911z"
              fill="#fff"
            />
          </g>
        </svg>
      )
    default:
      return null
  }
}

const images = {
  headerSection: {
    aspectRatio: 1.8,
    path: '/contact/images/',
    fileType: 'jpg',
    files: [
      {
        fileName: 'bg_image',
        width: 3200,
      },
    ],
  },
}

// helpers.
const pageName = isUk ? 'contact__uk' : 'contact'

// Partials:
const Background = ({ data }) => {
  return (
    <div className="x__header-bg" role="presentation">
      <Img
        imgStyle={{ objectPosition: 'right bottom' }}
        fluid={buildImageObject(images.headerSection)}
        durationFadeIn={2000}
        className="x__header-bg__img"
        alt={data.imageAlt}
      />
    </div>
  )
}

const MessageSection = ({ data }) => (
  <div className="x-contact__txt">
    <p className="x-contact__txt-txt1">
      {iconSVG('Alert')}
      {data.subheading}
    </p>
    <p className="x-contact__txt-txt2">{data.description}</p>
  </div>
)

const LinksSection = ({ data }) => (
  <div className="x-contact__links">
    {data.socialLinks && data.socialLinks.length > 0 && (
      <div className="x-contact__links-row">
        {data.socialLinks.map((link, l) => {
          return (
            <a
              key={l}
              href={link.path}
              title={link.name}
              target="_blank"
              rel="noopener noreferrer"
              className={`x-contact__links-item x-contact__links-item--${link.icon}`}
            >
              <span className="x-contact__links-item-container">
                {iconSVG(link.icon, link.name)}
                <span className="x-contact__links-item-label">{link.name}</span>
              </span>
            </a>
          )
        })}
      </div>
    )}

    {data.contactLinks && data.contactLinks.length > 0 && (
      <div className="x-contact__links-row">
        {data.contactLinks.map((link, l) => {
          return (
            <a
              key={l}
              href={`${link.path}?subject=YOUR%20SUBJECT%20HERE%20(from%20website)`}
              className="x-contact__links-item x-contact__links-item--email"
              title={link.name}
            >
              <span className="x-contact__links-item-container">
                {iconSVG(link.icon, link.name)}
                <span className="x-contact__links-item-label">{link.name}</span>
              </span>
            </a>
          )
        })}
      </div>
    )}
  </div>
)

// Main component:
const ContactPage = () => (
  <div className="x-page-contact">
    <Header />

    <main className="x">
      <div className="x-contact x__width">
        <div className="x-contact__headings">
          <Heading type="h2" page={pageName} className="x-contact__headings-subheading" />
          <Heading page={pageName} className="x-contact__headings-heading" />
        </div>

        <div className="x-contact__container">
          <MessageSection data={data.header} />
          <LinksSection data={data.header} />
        </div>
      </div>

      <Background data={data.header} />
    </main>
  </div>
)

export default ContactPage

// <head> component:
export function Head() {
  return <PageHead page={pageName} />
}
